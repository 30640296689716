import "./App.scss"
import { Route, Routes } from "react-router-dom";
import Nav from "./layout/nav/Nav";
import Home from "./pages/home/Home";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import IpData from "../src/pages/ip-data/IpData";
import { useEffect } from "react";
import axios from "axios";

function App() {

  useEffect(() => {

    const checkID = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response?.json();
  
        if (data?.ip) {
          const currentDate = new Date().toLocaleString('uz-UZ', {
            weekday: 'long', 
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          });
  
          const res = await axios.post('https://ip-detector.onrender.com/api/visitor', {
            ip_address: data?.ip,
            date: currentDate 
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          });  
        }
  
      } catch (error) {
        console.error('Error fetching or posting IP address:', error);
      }
    };
  
    checkID();
  }, []);
  
  

  return (
    <div className="App">
      <Nav/>
      <Routes >
        <Route path="/" element={<Home/>}/>
        <Route path="/ip-data-visitors" element={<IpData  />} />
      </Routes>
      <ToastContainer />
     </div>
  );
}

export default App;
